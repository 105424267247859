{
   "nn": {
      "shortcode": "N",
      "name": "Noun"
   },
   "vb": {
      "shortcode": "V",
      "name": "Verb"
   },
   "jj": {
      "shortcode": "A",
      "name": "Adjective"
   },
   "ab": {
      "shortcode": "Av",
      "name": "Adverb"
   },
   "abbrev": {
      "shortcode": "Ab",
      "name": "Abbreviation"
   },
   "article": {
      "shortcode": "Ar",
      "name": "Article"
   },
   "kn": {
      "shortcode": "Cj",
      "name": "Conjunction"
   },
   "hjälpverb": {
      "shortcode": "H",
      "name": "Helper Verb"
   },
   "ie": {
      "shortcode": "Im",
      "name": "Infinitive Marker"
   },
   "in": {
      "shortcode": "I",
      "name": "Interjection"
   },
   "latin": {
      "shortcode": "L",
      "name": "Latin"
   },
   "rg": {
      "shortcode": "#",
      "name": "Number"
   },
   "pc": {
      "shortcode": "Pp",
      "name": "Participle"
   },
   "prefix": {
      "shortcode": "Pf",
      "name": "Prefix"
   },
   "pp": {
      "shortcode": "P",
      "name": "Preposition"
   },
   "pn": {
      "shortcode": "Pr",
      "name": "Pronoun"
   },
   "pm": {
      "shortcode": "pN",
      "name": "Proper Noun"
   },
   "ps": {
      "shortcode": "Ps",
      "name": "Posessive"
   },
   "hp": {
      "shortcode": "rP",
      "name": "Relative Pronoun"
   },
   "sn": {
      "shortcode": "Sj",
      "name": "Subjunction"
   },
   "suffix": {
      "shortcode": "Sf",
      "name": "Suffix"
   }
}